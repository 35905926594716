const WALLETS_SET_STATE: 'WALLETS_SET_STATE' = 'WALLETS_SET_STATE';

const WALLETS_METAMASK_CONNECT: 'WALLETS_METAMASK_CONNECT' = 'WALLETS_METAMASK_CONNECT';

const WALLETS_WHITELIST: 'WALLETS_WHITELIST' = 'WALLETS_WHITELIST';
const WALLETS_CHECK_IS_WHITELISTED: 'WALLETS_CHECK_IS_WHITELISTED' = 'WALLETS_CHECK_IS_WHITELISTED';

const WALLETS_GET_CRAT_BALANCE: 'WALLETS_GET_CRAT_BALANCE' = 'WALLETS_GET_CRAT_BALANCE';
const WALLETS_APPROVE_TOKENS_SPEND: 'WALLETS_APPROVE_TOKENS_SPEND' = 'WALLETS_APPROVE_TOKENS_SPEND';
const WALLETS_SEND_TOKENS: 'WALLETS_SEND_TOKENS' = 'WALLETS_SEND_TOKENS';

const WALLETS_COLLECT_STAKE_DATA: 'WALLETS_COLLECT_STAKE_DATA' = 'WALLETS_COLLECT_STAKE_DATA';
const WALLETS_STAKE: 'WALLETS_STAKE' = 'WALLETS_STAKE';
const WALLETS_UNSTAKE: 'WALLETS_UNSTAKE' = 'WALLETS_UNSTAKE';

const WALLETS_SIGN_BUY: 'WALLETS_SIGN_BUY' = 'WALLETS_SIGN_BUY';

const WALLETS_DISCONNECT: 'WALLETS_DISCONNECT' = 'WALLETS_DISCONNECT';

export default {
  WALLETS_SET_STATE,

  WALLETS_METAMASK_CONNECT,

  WALLETS_WHITELIST,
  WALLETS_CHECK_IS_WHITELISTED,

  WALLETS_GET_CRAT_BALANCE,
  WALLETS_APPROVE_TOKENS_SPEND,
  WALLETS_SEND_TOKENS,

  WALLETS_COLLECT_STAKE_DATA,
  WALLETS_STAKE,
  WALLETS_UNSTAKE,

  WALLETS_SIGN_BUY,

  WALLETS_DISCONNECT,
};
