/* eslint-disable max-len */
import {
  select, put, takeLatest, call,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { walletSetState, collectStakeData } from 'store/wallet/actions';
import actionTypes from 'store/wallet/actionTypes';
import { createContract } from 'utils';
import { stakingFactoryAddress } from 'appConstants/contracts';
import stakingFactoryAbi from 'appConstants/contracts/stakingFactoryAbi.json';
import stakingAbi from 'appConstants/contracts/stakingAbi.json';
import walletsSelector from '../selectors';

export function* collectStakeDataSaga({ type }: ReturnType<typeof collectStakeData>) {
  try {
    yield put(apiActions.request(type));

    const myAddress = yield select(walletsSelector.getProp('address'));
    // @ts-expect-error: types mismatch
    const stakingFactroyContract = yield call(createContract, stakingFactoryAddress, stakingFactoryAbi);
    const totalStakers = yield stakingFactroyContract.methods.totalStakers().call();
    const totalStaked = yield stakingFactroyContract.methods.totalStaked().call();
    const availableRewards = yield stakingFactroyContract.methods.availableRewards().call();
    const stakingData = [];
    try {
      for (let i = 0; ; i += 1) {
        const stakingContractAddress = yield stakingFactroyContract.methods.userStakes(myAddress, i).call();
        // @ts-expect-error: types mismatch
        const stakingContract = yield call(createContract, stakingContractAddress, stakingAbi);
        const stakeInfo = yield stakingContract.methods.info().call();
        stakingData.push({ ...stakeInfo, stakingContractAddress });
      }
    } catch (err) {
      console.log(err);
    }

    yield put(walletSetState({
      stakingData,
      totalStakers,
      totalStaked,
      availableRewards,
    }));
    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.WALLETS_COLLECT_STAKE_DATA, collectStakeDataSaga);
}
