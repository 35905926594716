import { IProvider, ISettings, INetwork } from '@amfi/connect-wallet/dist/interface';

/* eslint-disable @typescript-eslint/no-explicit-any */
export * from './store';
export * from './components';
export * from './components/icon';
export * from './hooks';
export * from './routes';
export * from './link';
export * from './cryptoAssets';
export * from './redux';
export * from './ui';
export * from './utils';

export interface OptionalClassNameProp {
  className?: string;
}
export interface IConnectWallet {
  network: INetwork;
  provider: {
    [index: string]: IProvider;
  };
  settings: ISettings;
}
export interface IChainConfig {
  name: string;
  id: number;
  rpc: string;
  tx: {
    link: string;
  };
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  blockExp: string;
}

export interface IContracts {
  decimals: number;
  names: string[];
  type: string;
  params: {
    [index: string]: {
      mainnet: {
        address: string;
        abi: any[];
      };
      testnet: {
        address: string;
        abi: any[];
      };
    };
  };
}

export type TNullable<T> = T | null;
export type TOptionable<T> = T | undefined;
