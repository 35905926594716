/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, Component } from 'react';
import { contracts, isProduction } from 'config';

import { connect } from 'react-redux';
import { connectMetamask } from 'store/wallet/actions';
import { WalletStatus } from 'appConstants';
import { setNotification } from 'utils';
import { State } from 'types';
import { WalletService } from '..';

declare global {
  interface Window {
    ethereum: any;
  }
}

const walletConnectorContext = createContext<{
  connect:any;
  disconnect:() => void;
  walletService: WalletService;
  myAddress: string;
  isContractsExists: boolean;
}>({
      connect: null,
      disconnect: (): void => {},
      walletService: new WalletService(),
      myAddress: '',
      isContractsExists: false,
    });

class Connector extends Component<
any,
{
  provider: WalletService;
  address: string;
  isContractsExists: boolean;
}
> {
  constructor(props: any) {
    super(props);

    this.state = {
      provider: new WalletService(),
      address: '',
      isContractsExists: false,
    };

    this.connect = this.connect.bind(this);
    this.disconnect = this.disconnect.bind(this);
  }

  componentDidMount() {
    this.state.provider.connectWallet.initWeb3(
      isProduction
        ? 'https://bsc-dataseed.binance.org/'
        : 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    );
    const promises: Array<Promise<any>> = contracts.names.map((contract) => {
      const { address, abi } = contracts.params[contract][isProduction ? 'mainnet' : 'testnet'];

      return this.state.provider.connectWallet.addContract({
        name: contract,
        address,
        abi,
      });
    });

    Promise.all(promises)
      .then(() => {
        this.setState({
          isContractsExists: true,
        });
      })
      .catch(() => {
        this.disconnect();
      });

    if (localStorage.walletconnect) {
      this.connect('WalletConnect');
    }
  }

  connect = async (provider: 'WalletConnect' | 'MetaMask') => {
    try {
      const isConnected = await this.state.provider.initWalletConnect(
        'Binance-Smart-Chain',
        provider,
      );
      if (isConnected) {
        this.state.provider.getAccount().subscribe(
          (userAccount: any) => {
            if (this.props.userAddress !== '') {
              setNotification({
                type: 'success',
                title: 'Success',
                message: 'Wallet Connected',
              });
            }
            this.props.connectWallet({
              address: userAccount.address,
              status: WalletStatus.CONNECTED,
              wallet: provider,
            });
            if (this.state.address && userAccount.address !== this.state.address) {
              this.disconnect();
            } else {
              this.setState({
                address: userAccount.address,
              });

              this.state.provider.setAccountAddress(userAccount.address);
            }
          },
          () => {
            // alert(
            //   `Wrong Network, please select ${
            //     isProduction ? 'mainnet' : 'testnet'
            //   } network in your wallet and try again`,
            // );
            if (provider === 'MetaMask') {
              this.connect('MetaMask');
            } else {
              this.disconnect();
            }
          },
        );
      }
    } catch (err) {
      console.error(err);
      this.disconnect();
    }
  };

  disconnect() {
    delete localStorage.walletconnect;
    this.setState({
      address: '',
    });
  }

  render() {
    return (
      <walletConnectorContext.Provider
        value={{
          walletService: this.state.provider,
          connect: this.connect,
          disconnect: this.disconnect,
          myAddress: this.state.address,
          isContractsExists: this.state.isContractsExists,
        }}
      >
        {this.props.children}
      </walletConnectorContext.Provider>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  connectWallet: (payload: any) => dispatch(connectMetamask(payload)),
});

const mapStateToProps = (state: State) => ({
  userAddress: state.wallet.address,
});

export default connect(mapStateToProps, mapDispatchToProps)(Connector);

export function useWalletConnectorContext() {
  return useContext(walletConnectorContext);
}
