/* eslint-disable max-len */
import {
  select, put, call, takeEvery,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { stake } from 'store/wallet/actions';
import actionTypes from 'store/wallet/actionTypes';
import { stakingFactoryAddress } from 'appConstants/contracts';
import stakingFactoryAbi from 'appConstants/contracts/stakingFactoryAbi.json';
import { notificationModalSetState } from 'store/notificationModal/actions';
import walletsSelector from '../selectors';
import { collectStakeDataSaga } from './collectStakingData';
import { getCratBalanceSaga } from './getCratBalance';

export function* stakeSaga({ type, payload }: ReturnType<typeof stake>) {
  const { amount, duration, provider } = payload;
  try {
    yield put(apiActions.request(type));
    yield put(notificationModalSetState({
      isOpen: true,
      type: 'send',
      result: 'pending',
    }));

    const myAddress = yield select(walletsSelector.getProp('address'));
    const stakingFactroyContract = yield (new provider.eth.Contract(stakingFactoryAbi, stakingFactoryAddress));

    const { transactionHash } = yield stakingFactroyContract.methods.stake(amount, duration).send({
      from: myAddress,
    });

    yield call(collectStakeDataSaga, {
      type: actionTypes.WALLETS_COLLECT_STAKE_DATA,
    });

    yield call(getCratBalanceSaga, {
      type: actionTypes.WALLETS_GET_CRAT_BALANCE,
    });

    yield put(notificationModalSetState({
      isOpen: true,
      type: 'send',
      result: 'success',
      transactionHash,
    }));

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);

    yield put(notificationModalSetState({
      isOpen: true,
      type: 'send',
      result: 'reject',
    }));
  }
}

export default function* listener() {
  yield takeEvery(actionTypes.WALLETS_STAKE, stakeSaga);
}
