/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, { FC, useMemo } from 'react';
import { H2, Text } from 'components/Typography';
import {
  moneyBagTwo,
  RocketPng,
  flowerMoney,
  safeMoney,
} from 'assets/img';
import { Button, Carousel } from 'components';
import cx from 'classnames';
import { useScroll, useWindowSize } from 'hooks';
import styles from './styles.module.scss';
import { CoinPercent, MaxSupply } from './components';
import { stakeInfo } from './helper';

type Props = {
  scrollToBuy: () => void,
};
const Banner: FC<Props> = ({ scrollToBuy }) => {
  const { scrollY } = useScroll();
  const { width } = useWindowSize();

  const isMobile = useMemo(() => width < 980, [width]);
  return (
    <>
      <div className={cx(styles.container)}>
        <div className={styles.textBlock1}>
          <Text align="center" className={styles.text} size={isMobile ? 'xl' : 'xxl'} color="white">
            <Text align="center" className={styles.text} size={isMobile ? 'xl' : 'xxl'} weight="bold" tag="span" color="yellow">CratD2C</Text> Token, the very first Digital Utility Token Backed with Full
          </Text>
          <Text align="center" className={styles.text} size={isMobile ? 'xl' : 'xxl'} color="white">Transactions on <Text className={styles.text} size={isMobile ? 'xl' : 'xxl'} weight="bold" tag="span" color="yellow">E-commerce</Text> Platform.</Text>
          <Text align="center" className={styles.text} size={isMobile ? 'l' : 'xxl'} weight="bold" color="yellow">4Markets - 1Token</Text>
          <Text align="center" className={styles.text} size={isMobile ? 'xl' : 'xxl'} color="white">Stake CRAT tokens to earn more</Text>

        </div>
        <div className={styles.textBlock2}>
          <Text align="center" className={styles.text} size={isMobile ? 'xxl' : 'xxxl'} weight="bold" color="yellow">CRAT Yield Farming</Text>
          <Text align="center" className={styles.text} size={isMobile ? 'xxl' : 'xxxl'} weight="bold" color="white">(Staking)</Text>
        </div>
        <img
          className={styles.flowerImage}
          src={flowerMoney}
          alt="flowerMoney"
          style={{ transform: `${scrollY < 3000 && width > 900 ? `translateY(${scrollY / 20}px)` : 'none'}` }}
        />
        <img
          className={styles.safeImage}
          src={safeMoney}
          alt="safeMoney"
          style={{ transform: `${scrollY < 3000 ? `translateY(-${scrollY / 20}px)` : 'none'}` }}
        />
        <Text align="center" className={cx(styles.text, styles.textBlock3)} size={isMobile ? 'l' : 'xl'} color="white">
          CRAT yield farming protocol incentivizes rewards with 4-PLANS (Monthly, Quarterly, Half Yearly, & Yearly. CRAT is staked and locked up within these plans in a CRAT Blockchain Smart Contract. The following REWARDS and PAYOUT is automated with the smart contract at the expiration of the Staking Period.
        </Text>
        <Carousel classNameProp={styles.stakeInfoCarousel}>
          {stakeInfo.map(({ duration, apy, description }) => (
            <div className={styles.stakeInfoCard}>
              <Text align="center" color="white" className={styles.text} size="s" weight="bold">{duration}</Text>
              <H2 align="center" color="yellow" className={styles.text} weight="bold">{apy}</H2>
              <Text align="center" color="white" className={styles.text} size="m">{description}</Text>
            </div>
          ))}
        </Carousel>
        <div className={styles.title3}>
          <Text align="center" className={styles.text} size={isMobile ? 'xl' : 'xxl'} weight="bold" color="yellow">THE MORE CRAT TOKENS STAKED ...,</Text>
          <Text align="center" className={styles.text} size={isMobile ? 'xl' : 'xxl'} weight="bold" color="yellow">THE MORE MONEY TO BE MADE <span role="img" aria-label="money-bag-emoji">💰</span></Text>
        </div>
        <Button className={styles.buyBtn} size="big" color="yellow" onClick={scrollToBuy}>
          <Text size="xl" className={styles.buyBtnText}>
            <img className={styles.buyBtnImage} src={moneyBagTwo} alt="money bag" />STAKE CRAT TOKEN
          </Text>
        </Button>
      </div>
      <div className={styles.bottomBlock}>
        <img
          style={{ transform: `${scrollY < 3000 ? `translate(${scrollY / 10}px, -${scrollY / 10}px)` : 'none'}` }}
          src={RocketPng}
          alt=""
          className={styles.rocket}
        />
        <div className={styles.cards}>
          <div style={{ transform: `${scrollY < 3000 ? `translateX(${scrollY / 30}px)` : 'none'}` }}>
            <MaxSupply className={styles.maxSupply} />
          </div>
          <div style={{ transform: `${scrollY < 3000 ? `translateX(-${scrollY / 60}px)` : 'none'}` }}>
            <CoinPercent />
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
