export const getDaysLeft = (endTime: string | number): number => {
  const currentTime = Date.now();
  const oneDay = 86400 * 1000;

  if (currentTime >= endTime) {
    return 0;
  }

  return Math.ceil(Math.abs((+endTime - currentTime) / oneDay));
};
