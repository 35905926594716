import {
  request, success, error, reset,
} from 'store/ui/';

export default {
  request,
  success,
  error,
  reset,
};
