/* eslint-disable @typescript-eslint/no-explicit-any */
import { bnbMaskAddress } from 'appConstants';
import { IConnectWallet, IContracts } from 'types';

import { bep20Abi, saleAbi } from './abi';
import { isProduction } from './constants';

export * from './constants';

export const chains: {
  [key: string]: {
    name: string;
    chainId: number;
    provider: {
      [key: string]: any;
    };
    img?: any;
  };
} = {
  Trust: {
    name: 'Trust Wallet',
    chainId: isProduction ? 1 : 0,
    provider: {
      WalletConnect: {
        name: 'WalletConnect',
        useProvider: 'bridge',
        provider: {
          bridge: {
            bridge: 'https://bridge.walletconnect.org',
          },
        },
      },
    },
  },
  'Binance-Smart-Chain': {
    name: 'Binance-Smart-Chain',
    chainId: isProduction ? 56 : 97,
    provider: {
      MetaMask: { name: 'MetaMask' },
      WalletConnect: {
        name: 'WalletConnect',
        useProvider: 'rpc',
        provider: {
          rpc: {
            rpc: {
              [isProduction ? 56 : 97]: isProduction
                ? 'https://bsc-dataseed.binance.org/'
                : 'https://data-seed-prebsc-1-s1.binance.org:8545/',
            },
            chainId: isProduction ? 56 : 97,
          },
        },
      },
    },
  },
};

export const connectWallet = (chainName: string): IConnectWallet => {
  const chain = chains[chainName];

  return {
    network: {
      name: chain.name.toString(),
      chainID: chain.chainId,
    },
    provider: chain.provider,
    settings: { providerType: true },
  };
};

export enum TokenNames {
  CRAT = 'CRAT',
  USDT = 'USDT',
  WETH = 'WETH',
  BTCB = 'BTCB',
  BNB = 'BNB',
}

export enum ContractsNames {
  SALE = 'SALE',
  CRAT = 'CRAT',
  USDT = 'USDT',
  WETH = 'WETH',
  BTCB = 'BTCB',
  BNB = 'BNB',
}

export type IContractsNames = keyof typeof ContractsNames;

export const contracts: IContracts = {
  type: isProduction ? 'mainnet' : 'testnet',
  names: Object.keys(ContractsNames),
  decimals: 18,
  params: {
    SALE: {
      mainnet: {
        address: '',
        abi: saleAbi,
      },
      testnet: {
        address: '0x093B1DFcc5AD4922E7B6db49273f809dc070d0A2',
        abi: saleAbi,
      },
    },
    CRAT: {
      mainnet: {
        address: '',
        abi: bep20Abi,
      },
      testnet: {
        address: '0x731378937b413a44ac1F673445970916273fe715',
        abi: bep20Abi,
      },
    },
    USDT: {
      mainnet: {
        address: '',
        abi: bep20Abi,
      },
      testnet: {
        address: '0x38B2062bA8CC6c582cCEaA22F338bCd9e09cD56b',
        abi: bep20Abi,
      },
    },
    WETH: {
      mainnet: {
        address: '',
        abi: bep20Abi,
      },
      testnet: {
        address: '0x87C40486a9e0937613EC4006CC135eF233CBAe2f',
        abi: bep20Abi,
      },
    },
    BTCB: {
      mainnet: {
        address: '',
        abi: bep20Abi,
      },
      testnet: {
        address: '0xdbAFF29138fCb006403403318061D5b947009C93',
        abi: bep20Abi,
      },
    },
    BNB: {
      mainnet: {
        address: '',
        abi: bep20Abi,
      },
      testnet: {
        address: bnbMaskAddress,
        abi: bep20Abi,
      },
    },
  },
};
