import React, { FC } from 'react';
import cx from 'classnames';

import ReactPaginate from 'react-paginate';
import styles from './styles.module.scss';

type Props = {
  pageCount: number;
  pageRange: number;
  handlePageClick: (e: unknown) => void;
  className?: string;
};

const Pagination: FC<Props> = ({
  pageCount, pageRange, handlePageClick, className,
}) => (
  <ReactPaginate
    breakLabel="..."
    pageCount={pageCount}
    className={cx(styles.container, className)}
    renderOnZeroPageCount={undefined}
    pageRangeDisplayed={pageRange}
    marginPagesDisplayed={1}
    onPageChange={handlePageClick}
    nextLabel={null}
    previousLabel={null}
    containerClassName={styles.paginationContainer}
    pageClassName={styles.pageButton}
    pageLinkClassName={styles.pageLink}
    breakClassName={styles.pageButton}
    activeClassName={styles.activePageButton}
  />
);

export default Pagination;
