import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Layout, WhitelistModal } from 'containers';
import {
  Banner,
  Buy,
  MyStakes,
  RoadMap,
} from 'containers/Main';
import walletSelector from 'store/wallet/selectors';
import notificationModalSelector from 'store/notificationModal/selectors';
import { useDispatch } from 'react-redux';
import { checkIsWhitelisted, collectStakeData, getCratBalance } from 'store/wallet/actions';
import { useShallowSelector } from 'hooks';
import { NotificationModalState, State, WalletState } from 'types';
import { getTokens } from 'store/tokens/actions';
import { getStage } from 'store/stage/actions';
import { Videos } from 'containers/Main/BuyBlock/components';
import { useWalletConnectorContext } from 'services';
import NotificationModal from 'containers/NotificationModals';
import { notificationModalSetState } from 'store/notificationModal/actions';

const Main = () => {
  const buyBlockRef = useRef<HTMLDivElement>(null);
  const { connect } = useWalletConnectorContext();
  const {
    address, wallet, stakingData,
  } = useShallowSelector<State, WalletState>(walletSelector.getWallet);

  const {
    isOpen,
    result,
    type,
    transactionHash,
  } = useShallowSelector<State, NotificationModalState>(notificationModalSelector.getModalInfo);

  const dispatch = useDispatch();

  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = useCallback(() => {
    setModalOpen(!isModalOpen);
  }, [isModalOpen]);

  const closeNotificationModal = useCallback(() => {
    dispatch(notificationModalSetState({
      isOpen: false,
      result: '',
      type: '',
      transactionHash: '',
    }));
  }, []);

  useEffect(() => {
    dispatch(getStage());
    dispatch(getTokens());
    dispatch(collectStakeData());
    if (address) {
      dispatch(checkIsWhitelisted({ address }));
      dispatch(getCratBalance());
    }
  }, [address]);

  useEffect(() => {
    if (wallet === 'MetaMask') {
      connect('MetaMask');
    }
  }, [wallet]);

  const scrollToBuy = () => {
    if (buyBlockRef.current) {
      buyBlockRef.current.scrollIntoView();
    }
  };
  return (
    <Layout
      toggleModal={toggleModal}
    >
      <Banner
        scrollToBuy={scrollToBuy}
      />
      {/* <AboutProject /> */}
      {/* <PresalePlan /> */}
      <Buy
        buyBlockRef={buyBlockRef}
        toggleModal={toggleModal}
      />
      {(address !== '' && stakingData.length) ? <MyStakes /> : null}
      <Videos />
      <RoadMap />
      <WhitelistModal
        isOpen={isModalOpen}
        onClose={toggleModal}
      />
      {isOpen && (
      <NotificationModal
        isOpen={isOpen}
        type={type}
        result={result}
        onClose={closeNotificationModal}
        transactionHash={transactionHash || ''}
      />
      )}
    </Layout>
  );
};
export default Main;
