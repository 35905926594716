export const stakeInfo = [
  {
    duration: '1 month',
    apy: '3.5%',
    description: 'Payout Reward at the end of staking period',
  },
  {
    duration: '3 months',
    apy: '4%',
    description: 'monthly for three straight months, 12% Payout Reward at the end of staking period',
  },
  {
    duration: '6 months',
    apy: '4.5%',
    description: 'monthly for six consecutive months, 27% Payout Reward at the end of the staking period. ',
  },
  {
    duration: '12 months',
    apy: '5%',
    description: 'monthly for twelve straight months, 60% Payout Reward at the end of the staking period. ',
  },
];
