import { cratTokenAddress } from './contracts/index';

export const networkDescription = [
  {
    label: 'Network Name:',
    description: 'BSC Mainnet',
  },
  {
    label: 'New RPC URL: ',
    description: 'bsc-dataseed1.binance.org',
    copyable: true,
  },
  {
    label: 'Chain ID:',
    description: '56',
  },
  {
    label: 'Symbol:',
    description: 'BNB',
  },
  {
    label: 'Block Explorer URL:',
    description: 'bscscan.com',
    link: 'https://bscscan.com/',
  },
  {
    label: 'ADD CRAT TOKEN: ',
    description: cratTokenAddress,
    copyable: true,
  },
];
