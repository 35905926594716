import { LinkType } from 'types';

export const headerLinks: LinkType[] = [
  {
    name: 'LITEPAPER',
    link: 'https://cratprotocol.io/whitepaper/',
  },
  {
    name: 'GITHUB',
    link: 'https://github.com/samaros/CRAT-SMART-CONTRACT',
  },
];
