export enum Values {
  diagram,
  book,
  coin,
  bell,
  clock,
  wallet,
  adduser,
  copy,
  pencil,
  cross,
  checkmark,
  dropdown,
  calendar,
  arrowBack,
  arrow,
  warning,
  exit,
  refresh,
  telegram,
  facebook,
  github,
  instagram,
  twitter,
  image,
  discord,
  linkedin,
}

export type IconName = keyof typeof Values;
