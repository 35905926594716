/* eslint-disable max-len */
import {
  select, put, takeEvery, call,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { unstake } from 'store/wallet/actions';
import actionTypes from 'store/wallet/actionTypes';
import stakingAbi from 'appConstants/contracts/stakingAbi.json';
import { notificationModalSetState } from 'store/notificationModal/actions';
import walletsSelector from '../selectors';
import { collectStakeDataSaga } from './collectStakingData';
import { getCratBalanceSaga } from './getCratBalance';

function* saga({ type, payload }: ReturnType<typeof unstake>) {
  const { stakingContractAddress, provider } = payload;
  try {
    yield put(apiActions.request(type));

    yield put(notificationModalSetState({
      isOpen: true,
      type: 'send',
      result: 'pending',
    }));

    const myAddress = yield select(walletsSelector.getProp('address'));
    const stakingContract = yield new provider.eth.Contract(stakingAbi, stakingContractAddress);

    const { transactionHash } = yield stakingContract.methods.unstake().send({
      from: myAddress,
    });

    yield call(collectStakeDataSaga, {
      type: actionTypes.WALLETS_COLLECT_STAKE_DATA,
    });

    yield call(getCratBalanceSaga, {
      type: actionTypes.WALLETS_GET_CRAT_BALANCE,
    });

    yield put(notificationModalSetState({
      isOpen: true,
      type: 'send',
      result: 'success',
      transactionHash,
    }));

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);

    yield put(notificationModalSetState({
      isOpen: true,
      type: 'approve',
      result: 'reject',
    }));
  }
}

export default function* listener() {
  yield takeEvery(actionTypes.WALLETS_UNSTAKE, saga);
}
