/* eslint-disable react/no-array-index-key */
import React, { FC, useCallback } from 'react';
import { Text } from 'components/Typography';
import {
  Copyable, Icon, Button, Card,
} from 'components';
import cx from 'classnames';
import { shortenPhrase } from 'utils';
import walletSelector from 'store/wallet/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { connectMetamask } from 'store/wallet/actions';
import { metamask, trustWallet } from 'assets/img';
import { useWalletConnectorContext } from 'services';
import styles from './styles.module.scss';

type Props = {
  address: string,
  isAbsolute?: boolean,
  disconnect: () => void,
  className?: string,
};

const ConnectDropdownMenu: FC<Props> = ({
  isAbsolute = false, className,
}) => {
  const walletStorage = useSelector(walletSelector.getWallet);
  const { status, wallet } = walletStorage;
  const { connect } = useWalletConnectorContext();

  const dispatch = useDispatch();

  const handleDisconnect = useCallback(() => {
    dispatch(connectMetamask({
      address: '',
    }));
    localStorage.removeItem('walletconnect');
  }, []);

  const isConnected = status === 'CONNECTED';

  const handleConnect = async (walletName: string) => {
    if (walletName === 'Trust Wallet') {
      await connect('WalletConnect');
    } else {
      await connect('MetaMask');
    }
  };

  return (
    <Card className={cx(styles.container, { [styles.absolute]: isAbsolute }, className)}>
      {!isConnected && <Text tag="p" weight="bold" color="green">AVAILABLE WALLET</Text>}
      {!isConnected ? (
        <>
          <div
            className={styles.connectBtn}
            onClick={() => handleConnect('Metamask')}
          >
            <img className={styles.walletIcon} src={metamask} alt="" />
            <Text>Metamask</Text>
          </div>
          <div
            className={styles.connectBtn}
            onClick={() => handleConnect('Trust Wallet')}
          >
            <img className={styles.walletIcon} src={trustWallet} alt="" />
            <Text>Trust Wallet</Text>
          </div>
        </>
      ) : (
        <>
          <Text tag="p" weight="bold" color="green">ACCOUNT</Text>
          {wallet && <Text tag="p" color="black" size="xs">{`CONNECTED WITH ${wallet.toUpperCase()}`}</Text>}
          <Copyable withIcon valueToCopy={walletStorage.address} classNameIcon={styles.icon}>
            <div className={styles.addressContainer}>{shortenPhrase(walletStorage.address)}</div>
          </Copyable>
          <Button color="transparent" onClick={handleDisconnect}>
            <Icon className={styles.disconnectIcon} icon="exit" />
            DISCONNECT
          </Button>
        </>
      )}
    </Card>
  );
};

export default ConnectDropdownMenu;
