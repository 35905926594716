export const stakeOptions = [
  {
    label: '1 month',
    bonus: '3.5%',
    bonusValue: 3.5,
    value: 0,
  },
  {
    label: '3 month',
    bonus: '4%',
    bonusValue: 4,
    value: 1,
  },
  {
    label: '6 month',
    bonus: '4.5%',
    bonusValue: 4.5,
    value: 2,
  },
  {
    label: '12 month',
    bonus: '5%',
    bonusValue: 5,
    value: 3,
  },
];
