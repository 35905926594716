import React, { FC } from 'react';
import cx from 'classnames';
import { Text } from 'components/Typography';
import { cratStakedIcon, cratStakersIcon } from 'assets/img';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  cratStakers: number | string;
  cratStaked: number | string;
};

const StakeInfo: FC<Props> = ({
  className, cratStakers, cratStaked,
}) => (
  <div className={cx(styles.container, className)}>
    <div className={styles.cratInfoCard}>
      <div className={styles.cratInfoCardTitle}>
        <img src={cratStakersIcon} alt="" />
        <Text>TOTAL CRAT STAKERS</Text>
      </div>
      <Text className={styles.cratInfoCardText} color="green" align="center">{cratStakers}</Text>
    </div>
    <div className={styles.cratInfoCard}>
      <div className={styles.cratInfoCardTitle}>
        <img src={cratStakedIcon} alt="" />
        <Text>TOTAL CRAT STAKED</Text>
      </div>
      <Text className={styles.cratInfoCardText} color="green" align="center">{cratStaked}</Text>
    </div>
  </div>
);

export default StakeInfo;
