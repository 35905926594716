import React, { FC } from 'react';
import cx from 'classnames';
import { Button, Text } from 'components';
import styles from './styles.module.scss';

type Props = {
  sortAction: () => void;
  className?: string
};

const StakeTableHead: FC<Props> = ({
  sortAction,
  className,
}) => (
  <div className={cx(styles.tableHeadContainer, className)}>
    <Text className={styles.cratEarned}>YOUR CRAT EARNED IF STAKED FOR FULL TERM</Text>
    <Text className={styles.daysToUnstakeHead}>DAYS LEFT UNTIL FREE UNSTAKE</Text>
    <Button className={styles.cratStaked} onClick={sortAction} color="transparent">
      <Text className={styles.sortText} color="green" weight="bold">YOUR CRAT STAKED ▾</Text>
    </Button>
    <Text className={styles.reward}>REWARD %</Text>
    <Button color="transparentWhite" className={styles.hiddenButton}>UNSTAKE AND CLAIM</Button>
  </div>
);

export default StakeTableHead;
