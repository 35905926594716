import React, { FC } from 'react';
import cx from 'classnames';
import { Button, Text } from 'components';

import { crat } from 'assets/img';
import { getDaysLeft } from 'utils';
import styles from './styles.module.scss';

type Props = {
  cratEarned: string;
  daysToUnstake: string;
  cratStaked: string;
  reward: string;
  unstakeAction: () => void;
  className?: string
  isMobile?: boolean;
  isHead?: boolean;
};

const Stake: FC<Props> = ({
  cratEarned,
  daysToUnstake,
  cratStaked,
  reward,
  unstakeAction,
  className,
  isMobile = false,
}) => {
  const daysToUnstakeFormated = getDaysLeft(+daysToUnstake * 1000);
  return (
    <div className={cx(isMobile ? styles.containerMobile : styles.container, className)}>
      {isMobile ? (
        <div className={styles.stakeMobile}>
          <div>
            <Text size="xs" color="gray">Your CRAT earned if staked for full term</Text>
            <div className={cx(styles.cratEarned)}>
              <Text weight="bold">{cratEarned}</Text>
              <img src={crat} alt="" />
            </div>
          </div>
          <div>
            <Text size="xs" color="gray">Days left until free unstake</Text>
            <Text weight="bold" className={styles.daysToUnstake}>{`${daysToUnstakeFormated} ${daysToUnstakeFormated === 1 ? 'day' : 'days'}`}</Text>
          </div>
          <div>
            <Text size="xs" color="gray">Your CRAT staked</Text>
            <div className={cx(styles.cratStaked)}>
              <Text weight="bold">{cratStaked}</Text>
              <img src={crat} alt="" />
            </div>
          </div>
          <div>
            <Text size="xs" color="gray">Reward %</Text>
            <Text weight="bold" className={styles.reward}>{`${reward}%`}</Text>
          </div>
          <Button isFullWidth color="white" onClick={unstakeAction}>UNSTAKE AND CLAIM</Button>
        </div>
      ) : (
        <>
          <div className={cx(styles.cratEarned)}>
            <img src={crat} alt="" />
            <Text>{cratEarned}</Text>
          </div>
          <Text className={styles.daysToUnstake}>{`${daysToUnstakeFormated} ${daysToUnstakeFormated === 1 ? 'day' : 'days'}`}</Text>
          <div className={cx(styles.cratStaked)}>
            <img src={crat} alt="" />
            <Text>{cratStaked}</Text>
          </div>
          <Text className={styles.reward}>{`${reward}%`}</Text>
          <Button color="white" onClick={unstakeAction}>UNSTAKE AND CLAIM</Button>
        </>
      )}
    </div>
  );
};

export default Stake;
