import React, { FC } from 'react';
import cx from 'classnames';
import { Copyable, Text } from 'components';

import { networkDescription } from 'appConstants';
import { shortenPhrase } from 'utils';
import styles from './styles.module.scss';

type Props = {
  className?: string
};

const promoVideos = [
  {
    src: '/videos/promo2.mp4',
    timeframe: '0.1',
  },
  {
    src: '/videos/promo1.MP4',
    timeframe: '1.6',
  },
];

const Videos: FC<Props> = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <div className={cx(styles.videoResponsive, styles.videosBlock)}>
      {promoVideos.map(({ src, timeframe }, index) => (
        <div key={src + String(index)} className={styles.videoContainer}>
          <video controls src={`${src}#t=${timeframe}`}>
            <track kind="captions" />
          </video>
        </div>
      ))}
    </div>
    <div className={cx(styles.description)}>
      {networkDescription.map(({
        label, description, link, copyable,
      }) => (
        <div key={label} className={styles.descriptionBlock}>
          <Text className={styles.descriptionKey} weight="bold" color="white">{label}</Text>
          {link && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={link}
          >
            <Text weight="bold" className={styles.descriptionLink}>{description}</Text>
          </a>
          )}
          {copyable && (
          <Copyable
            withIcon
            classNameIcon={styles.copyIcon}
            valueToCopy={description}
          >
            <Text weight="bold" className={styles.descriptionLink}>{shortenPhrase(description)}</Text>
          </Copyable>
          )}
          {!link && !copyable && (
            <Text weight="bold" className={styles.descriptionText}>{description}</Text>
          )}
        </div>
      ))}
    </div>
  </div>
);

export default Videos;
