/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  select, put, takeLatest, call,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { approveTokensSpend } from 'store/wallet/actions';
import { stakeSaga } from 'store/wallet/saga/stake';
import actionTypes from 'store/wallet/actionTypes';
import { getTokenAmount } from 'utils';
import { cratTokenAddress, stakingFactoryAddress } from 'appConstants/contracts';
import erc20Abi from 'appConstants/contracts/erc20Abi.json';
import { notificationModalSetState } from 'store/notificationModal/actions';
import walletsSelector from '../selectors';

function* saga({ type, payload }: ReturnType<typeof approveTokensSpend>) {
  const {
    decimals, amount, provider, duration,
  } = payload;

  try {
    yield put(apiActions.request(type));

    yield put(notificationModalSetState({
      isOpen: true,
      type: 'approve',
      result: 'pending',
    }));
    const myAddress = yield select(walletsSelector.getProp('address'));
    const cratTokenContract = yield (new provider.eth.Contract(erc20Abi, cratTokenAddress));

    const allowance = yield call(cratTokenContract.methods.allowance(
      myAddress,
      stakingFactoryAddress,
    ).call);
    if (+allowance < +getTokenAmount(amount, decimals)) {
      yield call(cratTokenContract.methods.approve(
        stakingFactoryAddress,
        getTokenAmount(amount, decimals),
      ).send, { from: myAddress });
    }

    yield put(notificationModalSetState({
      isOpen: true,
      type: 'approve',
      result: 'success',
    }));

    yield call(stakeSaga, {
      type: actionTypes.WALLETS_STAKE,
      payload: {
        amount,
        duration,
        provider,
      },
    });

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);

    yield put(notificationModalSetState({
      isOpen: true,
      type: 'approve',
      result: 'reject',
    }));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.WALLETS_APPROVE_TOKENS_SPEND, saga);
}
