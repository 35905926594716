/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useCallback } from 'react';
import cx from 'classnames';
import { Modal, Text, Button } from 'components';
import { unstakeWarning } from 'assets/img';
import { useDispatch } from 'react-redux';
import { unstake } from 'store/wallet/actions';
import { useWalletConnectorContext } from 'services';
import styles from './styles.module.scss';

type Props = {
  className?: string
  isOpen: boolean,
  onClose: () => void,
  unstakeAction?: () => void;
  address: string;
};

const UnstakeWarningModal: FC<Props> = ({
  className, isOpen, onClose, address,
}) => {
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();

  const handleUnstake = useCallback(() => {
    onClose();
    dispatch(unstake({
      stakingContractAddress: address,
      provider: walletService.Web3(),
    }));
  }, [address]);
  return(
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className={cx(styles.container, className)}
      classNameContent={styles.container}
    >
      <img src={unstakeWarning} alt="" />
      <Text align="center" size="xl" weight="bold" style={{ color: '#F09242' }}>Staking period hasn`t expired yet.</Text>
      <Text align="center">You won`t get any rewards if you unstake right now </Text>
      <Button size="big" color="green" isFullWidth onClick={handleUnstake}>
        <Text size="l" weight="bold" color="white">OK</Text>
      </Button>
    </Modal>
  );
};

export default UnstakeWarningModal;
