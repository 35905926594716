/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FC, useCallback, useMemo, useState,
} from 'react';
import cx from 'classnames';
import { Text } from 'components/Typography';
import { getTokenAmount, getTokenAmountDisplay, validateOnlyNumbers } from 'utils';
import { Button, Input, Select } from 'components';
import { useDispatch } from 'react-redux';
import { approveTokensSpend } from 'store/wallet/actions';
import { useWalletConnectorContext } from 'services';
import { cratDecimals } from 'appConstants';
import { stakeOptions } from './stakeBlockHelpers';
import styles from './styles.module.scss';

type Props = {
  availableRewards: string;
  className?: string,
  cratBalance: string;
};

const StakeBlock: FC<Props> = ({
  availableRewards,
  cratBalance,
  className,
}) => {
  const dispatch = useDispatch();

  const { walletService } = useWalletConnectorContext();

  const [selectedStakeType, setSelectedStakeType] = useState(stakeOptions[0]);
  const [stakeAmount, setStakeAmount] = useState('');

  const handleStakeType = useCallback((value) => {
    setSelectedStakeType(value);
  }, [selectedStakeType]);

  const handleStakeAmount = useCallback((e) => {
    if (validateOnlyNumbers(e.target.value)) {
      setStakeAmount(e.target.value);
    }
  }, [stakeAmount]);

  const handleStake = useCallback(() => {
    setStakeAmount('');
    dispatch(approveTokensSpend({
      amount: getTokenAmount(stakeAmount, cratDecimals),
      duration: selectedStakeType.value,
      provider: walletService.Web3(),
      decimals: cratDecimals,
    }));
  }, [selectedStakeType, stakeAmount]);

  const handleMaxStake = useCallback(() => {
    setStakeAmount(getTokenAmountDisplay(cratBalance, cratDecimals));
  }, [cratBalance]);

  const bonusReward = useMemo(() => (+getTokenAmount(stakeAmount, cratDecimals) / 100) *
    selectedStakeType.bonusValue * +selectedStakeType.label.split(' ')[0],
  [stakeAmount, selectedStakeType]);

  const isEnoughRewards = useMemo(() => {
    if (!+availableRewards) {
      return false;
    }
    return +availableRewards >= bonusReward;
  },
  [bonusReward, availableRewards]);

  const isStakeButtonDisabled = !+stakeAmount ||
    !isEnoughRewards ||
    +cratBalance < +getTokenAmount(stakeAmount, cratDecimals);

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.balance}>
        <Text>YOUR CRAT BALANCE</Text>
        <Text color="green">{`${getTokenAmountDisplay(cratBalance, cratDecimals)} CRAT`}</Text>
      </div>
      <div className={styles.inputMask}>
        <Input
          value={stakeAmount}
          name="spendToken"
          label="STAKE"
          onChange={handleStakeAmount}
          isBorder={false}
          classNameInput={styles.input}
        />
        <Select
          options={stakeOptions as any}
          value={selectedStakeType as any}
          hideSelectedOptions
          onChange={handleStakeType}
          classNameControl={styles.select}
        />
      </div>
      <Button onClick={handleMaxStake} size="normal" color="transparent" isFullWidth>
        <Text style={{ textDecoration: 'underline' }} size="xs" color="green">Max stake</Text>
      </Button>
      <Text color="green" align="center" weight="medium">{`${selectedStakeType.bonus} MONTHLY REWARD`}</Text>
      <Button
        onClick={handleStake}
        disabled={isStakeButtonDisabled}
        color="green"
        size="big"
        className={styles.stakeBtn}
      >
        <Text color="white" align="center" weight="medium">STAKE</Text>
      </Button>
      {!isEnoughRewards && <Text size="s" align="center" color="red">Not enough rewards on staking contract</Text>}
      <Text size="s" align="center">Please note that if you unstake CRAT before the determined staking period past you won`t get the rewards</Text>
    </div>
  );
};

export default StakeBlock;
