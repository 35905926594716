import { cratTokenAddress, stakingFactoryAddress } from './contracts';

export const importantAddresses = [
  {
    name: 'TOKEN CONTRACTS',
    address: cratTokenAddress,
  },
  {
    name: 'STAKING CONTRACT',
    address: stakingFactoryAddress,
  },
];
