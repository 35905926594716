export default [{ inputs: [{ internalType: 'address', name: '_CRAT', type: 'address' }, { internalType: 'uint256', name: '_CRAT_DECIMALS', type: 'uint256' }], stateMutability: 'nonpayable', type: 'constructor' }, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32',
  }, {
    indexed: true, internalType: 'bytes32', name: 'previousAdminRole', type: 'bytes32',
  }, {
    indexed: true, internalType: 'bytes32', name: 'newAdminRole', type: 'bytes32',
  }],
  name: 'RoleAdminChanged',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32',
  }, {
    indexed: true, internalType: 'address', name: 'account', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'sender', type: 'address',
  }],
  name: 'RoleGranted',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32',
  }, {
    indexed: true, internalType: 'address', name: 'account', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'sender', type: 'address',
  }],
  name: 'RoleRevoked',
  type: 'event',
}, {
  inputs: [], name: 'CRAT', outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'DEFAULT_ADMIN_ROLE', outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'LIMITS', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'SIGNER_ROLE', outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'STAGES', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'allAmounts', outputs: [{ internalType: 'uint256[8]', name: '', type: 'uint256[8]' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'allLimits', outputs: [{ internalType: 'uint256[8]', name: '', type: 'uint256[8]' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'allStages', outputs: [{ internalType: 'uint256[8]', name: '', type: 'uint256[8]' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'amounts', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'tokenToPay', type: 'address' }, { internalType: 'uint256', name: 'amountToPay', type: 'uint256' }, { internalType: 'uint256', name: 'amountToReceive', type: 'uint256' }, { internalType: 'uint256', name: 'endTime', type: 'uint256' }, { internalType: 'bytes', name: 'signature', type: 'bytes' }], name: 'buy', outputs: [], stateMutability: 'payable', type: 'function',
}, {
  inputs: [], name: 'determineStage', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }], name: 'getRoleAdmin', outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, { internalType: 'address', name: 'account', type: 'address' }], name: 'grantRole', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, { internalType: 'address', name: 'account', type: 'address' }], name: 'hasRole', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'toSend', type: 'address' }], name: 'pullToken', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'receiver', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, { internalType: 'address', name: 'account', type: 'address' }], name: 'renounceRole', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, { internalType: 'address', name: 'account', type: 'address' }], name: 'revokeRole', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_receiver', type: 'address' }], name: 'setReceiver', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'start', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'startTime', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }], name: 'supportsInterface', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
}];
