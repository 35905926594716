import { put, takeLatest } from 'redux-saga/effects';
import { WalletStatus } from 'appConstants';
import { connectMetamask, walletSetState, disconnectWallet } from 'store/wallet/actions';
import actionTypes from 'store/wallet/actionTypes';

function* connectMetamaskSaga({ payload }: ReturnType<typeof connectMetamask>) {
  try {
    const { address, status, wallet } = payload;
    if (status === WalletStatus.AVAILABLE) {
      yield put(walletSetState({
        status: address ? WalletStatus.CONNECTED : WalletStatus.AVAILABLE,
        address,
        wallet,
      }));
    } else {
      yield put(walletSetState({
        status,
        address,
        wallet,
      }));
    }
  } catch (err) {
    yield put(disconnectWallet({
      address: '',
    }));
    console.log(err);
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.WALLETS_METAMASK_CONNECT, connectMetamaskSaga);
}
