import React, { FC, RefObject, useCallback } from 'react';
import { Text } from 'components/Typography';
import { Button } from 'components';
import { State, WalletState } from 'types';
import walletSelector from 'store/wallet/selectors';
import { useShallowSelector } from 'hooks';
import { getTokenAmountDisplay } from 'utils';
import { cratDecimals, WalletStatus } from 'appConstants';
import cx from 'classnames';
import styles from './styles.module.scss';
import {
  StakeInfo,
  ImportantAddresses,
  StakeBlock,
} from './components';

type Props = {
  buyBlockRef: RefObject<HTMLDivElement>,
  toggleModal: () => void,
};

const BuyBlock: FC<Props> = ({
  buyBlockRef,
}) => {
  const {
    status,
    cratBalance,
    totalStaked,
    totalStakers,
    availableRewards,
  } = useShallowSelector<State, WalletState>(walletSelector.getWallet);

  const isConnected = status === WalletStatus.CONNECTED;

  const handleConnectWalletButton = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div ref={buyBlockRef} className={styles.container}>
      <div className={cx(styles.buyContainer, { [styles.connectedBuyContainer]: isConnected })}>
        <Text align="center" size="xxl" color="white">STAKE</Text>
        <StakeInfo
          cratStakers={totalStakers}
          cratStaked={getTokenAmountDisplay(totalStaked.toString(), cratDecimals)}
          className={styles.cryptoAssets}
        />
        {(isConnected) && (
          <StakeBlock availableRewards={availableRewards} cratBalance={cratBalance.toString()} />
        )}
        {/** BUTTON LOGIC START */}
        {!isConnected && (
          <>
            <Button
              icon="wallet"
              iconClassName={styles.walletIcon}
              className={styles.buyBtn}
              size="big"
              color="yellow"
              onClick={handleConnectWalletButton}
            >
              <Text size="l" align="center" color="green">CONNECT WALLET TO STAKE CRAT</Text>
            </Button>
          </>
        )}
        {/* {(isConnected && !isWhitelisted) && (
          <div className={styles.whitelistBlock}>
            <Text weight="medium" align="center">You are NOT whitelisted for PreSale events!</Text>
            <Text weight="medium" align="center">Whitelist now:</Text>
            <Button
              className={styles.buyBtn}
              size="big"
              color="white"
              onClick={toggleModal}
            >
              <Text size="l" align="center" color="green">WHITELIST</Text>
            </Button>
          </div>
        )} */}
        {/** BUTTON LOGIC END */}
      </div>
      <ImportantAddresses />
    </div>
  );
};

export default BuyBlock;
