/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import cx from 'classnames';

import {
  H1, Pagination, Text,
} from 'components';
import { State, UIState, WalletState } from 'types';
import { useShallowSelector, useWindowSize } from 'hooks';
import walletSelector from 'store/wallet/selectors';
import uiSelector from 'store/ui/selectors';
import { getTokenAmountDisplay } from 'utils';
import { useDispatch } from 'react-redux';
import { unstake } from 'store/wallet/actions';
import { useWalletConnectorContext } from 'services';
import { cratDecimals } from 'appConstants';
import { moneyBag, Pending } from 'assets/img';
import styles from './styles.module.scss';
import { Stake, UnstakeWarningModal } from './components';
import StakeTableHead from './components/Stake/tableHead';

type Props = {
  className?: string
};

const ITEMS_PER_PAGE = 10;

const MyStakes: FC<Props> = ({ className }) => {
  const dispatch = useDispatch();

  const { walletService } = useWalletConnectorContext();

  const { width } = useWindowSize();

  const { stakingData } = useShallowSelector<State, WalletState>(walletSelector.getWallet);
  // @ts-ignore
  const { WALLETS_COLLECT_STAKE_DATA } = useShallowSelector<State, UIState>(uiSelector.getUI);

  const [sortDirection, setSortDirection] = useState(false);
  const [isUnstakeWarningModalOpen, setUnstakeWarningModalOpen] = useState(false);
  const [currentStakingContractAddress, setCurrentStakingContractAddress] = useState('');

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const closeUnstakeWarningModal = useCallback(() => {
    setUnstakeWarningModalOpen(false);
  }, []);

  const openUnstakeWarningModal = useCallback(() => {
    setUnstakeWarningModalOpen(true);
  }, []);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * ITEMS_PER_PAGE) % stakingData.length;
    setItemOffset(newOffset);
  };

  const handleUnstake = (stakeData: any) => {
    setCurrentStakingContractAddress(stakeData.stakingContractAddress);
    if (stakeData.endTime * 1000 < Date.now()) {
      dispatch(unstake({
        stakingContractAddress: stakeData.stakingContractAddress,
        provider: walletService.Web3(),
      }));
    } else {
      openUnstakeWarningModal();
    }
  };

  const handleSort = useCallback(() => {
    if (sortDirection) {
      // @ts-ignore
      setCurrentItems(currentItems.sort((a, b) => a.stakedAmount - b.stakedAmount));
    } else {
      // @ts-ignore
      setCurrentItems(currentItems.sort((a, b) => b.stakedAmount - a.stakedAmount));
    }
    setSortDirection(!sortDirection);
  }, [sortDirection, currentItems]);

  useEffect(() => {
    const endOffset = itemOffset + ITEMS_PER_PAGE;
    setCurrentItems(stakingData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(stakingData.length / ITEMS_PER_PAGE));
  }, [itemOffset, ITEMS_PER_PAGE, stakingData]);

  const isMobile = useMemo(() => width < 721, [width]);
  return (
    <div className={cx(styles.container, className)}>
      <H1 weight="normal" align="center" className={styles.title}>MY STAKES</H1>
      <Text style={{ maxWidth: '500px', margin: '0 auto' }} align="center">
        Please note that if you unstake CRAT before the determined staking period past you won`t get CRAT rewards
      </Text>
      <div className={styles.stakesContainer}>
        <img className={styles.moneyBag} src={moneyBag} alt="" />
        {WALLETS_COLLECT_STAKE_DATA === 'REQUEST' && <img src={Pending} alt="pending" className={styles.spinner} />}
        {!isMobile && <StakeTableHead sortAction={handleSort} />}
        {currentItems.map((stake: any) => (
          <Stake
            key={stake.endTime}
            cratStaked={getTokenAmountDisplay(stake.stakedAmount, cratDecimals)}
            reward={(stake.rewardAmount / (stake.stakedAmount / 100)).toString()}
            daysToUnstake={stake.endTime}
            cratEarned={getTokenAmountDisplay(stake.rewardAmount, cratDecimals)}
            unstakeAction={() => handleUnstake(stake)}
            isMobile={isMobile}
          />
        ))}
      </div>
      {stakingData.length > ITEMS_PER_PAGE && <Pagination pageCount={pageCount} pageRange={5} handlePageClick={handlePageClick} />}
      <UnstakeWarningModal
        isOpen={isUnstakeWarningModalOpen}
        onClose={closeUnstakeWarningModal}
        address={currentStakingContractAddress}
      />
      <div className={styles.fade} />
    </div>
  );
};

export default MyStakes;
